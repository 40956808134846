import React, { useEffect } from 'react'
import { GiFlagObjective, GiStairsGoal } from 'react-icons/gi'
import { FcIdea } from 'react-icons/fc'
import Principle from '../../assets/SochPrinciple.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Index = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <>
      <div name='about' className='h-auto w-full mt-5 sm:mt-28'>
        <div className='flex flex-col justify-center items-center'>
          <div className=''>
            <h1
              data-aos='fade-up'
              data-aos-duration='1500'
              className='text-[25px] sm:text-[36px] uppercase font-extrabold'
            >
              <span className='text-[#caa046]'>About </span>
              <span className='text-[#caa046]'>Us</span>
            </h1>
            <hr />
          </div>
          <div className='flex flex-col items-center justify-center w-[90%] mt-5 sm:mt-16 space-y-28 p-2'>
            <div>
              <p className='text-md md:text-xl lg:text-2xl font-[450] tracking-wide items-center text-center leading-loose'>
                Situated in a peaceful and serene environment, Soch College of
                IT affiliated to Tribhuvan University, focuses on providing
                qualitative and affordable IT education.
              </p>
            </div>
            <div className='block lg:flex justify-center items-center space-y-6 lg:space-y-0 space-x-0  xl:space-x-12'>
              <div
                data-aos='fade-right'
                data-aos-duration='1500'
                className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
              >
                <div className='card-body'>
                  <div className='flex items-center'>
                    <span>
                      <FcIdea className='text-4xl mr-4' />
                    </span>
                    <h2 className='card-title text-[#66071e]'>Our Mission</h2>
                  </div>
                  <hr />
                  <p className='items-center text-center sm:text-justify break-normal leading-tight sm:leading-normal tracking-tight sm:tracking-wide mt-2'>
                    Provide easy access to qualitative and affordable education
                    to produce skillful human resources.
                  </p>
                </div>
              </div>
              <div
                data-aos='fade-up'
                data-aos-duration='1500'
                className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
              >
                <div className='card-body'>
                  <div className='flex items-center'>
                    <span>
                      <GiStairsGoal className='text-4xl mr-4 text-[#F2c460]' />
                    </span>
                    <h2 className='card-title text-[#66071e]'>Our Vision</h2>
                  </div>
                  <hr />
                  <p className='items-center text-center sm:text-justify leading-tight sm:leading-normal tracking-tight sm:tracking-wide mt-2'>
                    Advance technical education through scholarship, leadership
                    and practical exposure.
                  </p>
                </div>
              </div>
              <div
                data-aos='fade-left'
                data-aos-duration='1500'
                className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
              >
                <div class='card-body'>
                  <div className='flex items-center'>
                    <span>
                      <GiFlagObjective className='text-4xl mr-4 text-[#F2c460]' />
                    </span>
                    <h2 className='card-title text-[#66071e]'>
                      Our Objectives
                    </h2>
                  </div>
                  <hr />
                  <p className='items-center text-center sm:text-justify leading-tight sm:leading-normal tracking-tight sm:tracking-wide mt-2'>
                    Treat each other with dignity and respect. Ensure students
                    can achieve their full potential.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-5 lg:p-28'>
          <div
            data-aos='fade-up'
            data-aos-duration='2000'
            className='heroimage block lg:flex justify-between items-center mt-4 space-y-10 space-x-0 lg:space-y-0 lg:space-x-10 p-6 rounded'
          >
            <div>
              <img
                className='rounded shadow'
                src={Principle}
                width='1400px'
                height='900px'
                alt='principal'
              />
            </div>
            <div className='text-left sm:text-justify items-center space-y-4'>
              <h1 className='tracking-normal sm:tracking-wide flex items-center space-x-2 capitalize text-[16px] sm:text-[25px] font-bold'>
                <span className='text-[#66071e]'>Principal</span>
                <span className='text-[#66071e]'>Message</span>
              </h1>
              <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                As principal of this college, I am pleased to welcome students,
                lecturers, visitors, staff and parents. I encourage you to
                explore this website to learn more about this college.
              </p>
              <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                We deliver courses that ensure that our students are getting the
                21st century education they need. Active participation in an
                education that challenges critical thinking, provides career
                preparation, instills values, and inspires lifelong learning is
                encouraged in our college.
              </p>
              <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                The dedicated lecturer and staff in our college are supportive
                because they see students as an investment in the future.
              </p>
              <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                College is about so much more than academic success and at SOCH
                you will find much more. You will make friends for a lifetime,
                establish networks that will ease your transition into your
                working life and come to think of college as your home at SOCH.
              </p>
              <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                The future of this college is very bright, and I am honored to
                be part of this institution.
              </p>
              {/* <hr /> */}
              <div className=''>
                <p className='text-[16px] sm:text-[25px]  text-[#66071e] font-bold'>
                  Mr. Sushil Adhikari
                </p>
                <span className='text-[14px] sm:text-[16px] font-lg sm:font-bold'>
                  M.Sc.Computer Science
                  <br />
                  <span>(Technical University - Berlin, Germany)</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
