import React from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import Slider1 from '../../assets/Reading2.JPG'
import ClassPhoto from '../../assets/class-photo.jpg'
import Sports1 from '../../assets/sports1.png'
import View from '../../assets/view.jpg'
import ComputerLab from '../../assets/computer-lab.jpg'
import CulturalEvent from '../../assets/culturalevent.jpg'
import SportsWeek from '../../assets/sportsweek.jpg'
import Library from '../../assets/library.jpg'
import Ground from '../../assets/ground.jpg'
import Balcony from '../../assets/balcony.jpg'
import BoysSports from '../../assets/boyssports.jpg'
import ComputerLab1 from '../../assets/computerlab5.jpg'
import TugOfWar from '../../assets/tugofwar.jpg'
import GirlsPlaying from '../../assets/girlsplaying.jpg'

const Index = () => {
  return (
    <div
      name='gallery'
      className='aboutimage bg-fixed h-auto w-full flex flex-col justify-center items-center mb-20 mt-10 sm:mt-16 p-3 sm:p-10'
    >
      <div className='mb-5 sm:mb-16'>
        <h1
          data-aos='fade-up'
          data-aos-duration='1500'
          className='text-[25px] sm:text-[36px] uppercase font-extrabold'
        >
          <span className='text-[#caa046]'>Gallery</span>
        </h1>
        <hr />
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
        className='clientswiper'
      >
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={Slider1}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={ClassPhoto}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={Sports1}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={View}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={ComputerLab}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={CulturalEvent}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={SportsWeek}
            alt='standard'
          />
        </SwiperSlide>
      </Swiper>
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
        className='mt-10 sm:mt-16 clientswiper'
      >
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={Library}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={Balcony}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={Ground}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={BoysSports}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={ComputerLab1}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={TugOfWar}
            alt='standard'
          />
        </SwiperSlide>
        <SwiperSlide className='drop-shadow-md'>
          <img
            width={1000}
            height={500}
            className='img shadow drop-shadow'
            src={GirlsPlaying}
            alt='standard'
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Index
