import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Logo from '../../assets/sochlogo.png'

function Index() {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  return (
    <div className='fixed z-50 text-[#F7F4E9] bg-[whitesmoke] w-full h-auto scroll-smooth shadow-sm'>
      <div className='flex justify-between items-center p-4'>
        <div className='flex flex-col items-center'>
          <div>
            <img
              className='w-[50px] h-[50px] sm:w-[100px] sm:h-[100px] lg:w-[110px] lg:h-[110px]'
              src={Logo}
              alt='soch college'
            />
          </div>
          <div>
            <p className='text-[7px] sm:text-[10px] font-bold uppercase space-x-0 sm:space-x-1 mt-1'>
              <span className=' text-[#66071e]'>Since</span>
              <span className=' text-[#caa046]'> 2070</span>
            </p>
          </div>
        </div>
        <div className='navigation'>
          <ul className='hidden lg:flex lg:items-center lg:space-x-24 font-[500] text-lg'>
            <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
              <Link to='home' smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
              <Link to='about' smooth={true} duration={800}>
                About
              </Link>
            </li>
            <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
              <Link to='programmes' smooth={true} duration={1100}>
                Programmes
              </Link>
            </li>
            <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
              <Link to='gallery' smooth={true} duration={1400}>
                Gallery
              </Link>
            </li>
          </ul>
        </div>

        <div className='navigation'>
          <ul className='hidden lg:flex lg:items-center lg:space-x-16 text-lg cursor-pointer'>
            <li className='text-whitesmoke'>
              <div className='text-[#66071e] bg-transparent border border-solid border-[#66071e] hover:bg-[#66071e] hover:text-white active:bg-[#5ece81]] font-bold uppercase text-sm px-6 py-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  active show rounded-full hover:duration-500'>
                <Link to='contact' smooth={true} duration={1700}>
                  Get Started
                </Link>
              </div>
            </li>
          </ul>
        </div>

        {/* Hamburger Menu  */}
        <div
          onClick={handleClick}
          className='lg:hidden z-50 hover:cursor-pointer text-2xl'
        >
          {!nav ? (
            <span className='text-black text-3xl sm:text-[36px]'>
              <svg
                width='30'
                height='30'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4 8H20M4 16H20'
                  stroke='#111827'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          ) : (
            <span className='text-black text-3xl sm:text-[36px]'>
              <svg
                width='30'
                height='30'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 18L18 6M6 6L18 18'
                  stroke='#111827'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          )}
        </div>

        <ul
          className={
            !nav
              ? 'hidden'
              : 'absolute lg:hidden bg-[whitesmoke] top-0 left-0 w-full h-screen z-10 flex flex-col justify-center items-center cursor-pointer space-y-16 p-10'
          }
        >
          <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
            <Link onClick={handleClick} to='home' smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
            <Link onClick={handleClick} to='about' smooth={true} duration={800}>
              About
            </Link>
          </li>
          <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
            <Link
              onClick={handleClick}
              to='programmes'
              smooth={true}
              duration={1100}
            >
              Programmes
            </Link>
          </li>
          <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
            <Link
              onClick={handleClick}
              to='gallery'
              smooth={true}
              duration={1400}
            >
              Gallery
            </Link>
          </li>
          <li className='text-whitesmoke'>
            <div className='text-[#66071e] bg-transparent border border-solid border-[#66071e] hover:bg-[#66071e] hover:text-white active:bg-[#5ece81]] font-bold uppercase text-sm px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  active show rounded-full hover:duration-500'>
              <Link
                onClick={handleClick}
                to='contact'
                smooth={true}
                duration={1700}
              >
                Get Started
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Index
