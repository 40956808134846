import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Building from '../../assets/sochcollegebuilding.svg'
import ComputerLab from '../../assets/computer-lab.jpg'
import DigitalLab from '../../assets/digitallab.jpg'
import Library from '../../assets/Reading1.JPG'
import Sports from '../../assets/sports1.png'
import Canteen from '../../assets/canteen1.jpeg'
import AILab from '../../assets/ailab.jpeg'
import LecturerHall from '../../assets/lecturehall.jpeg'
import NetworkLab from '../../assets/networklab.jpeg'
import { IoIosArrowDropdown } from 'react-icons/io'
// import { Autoplay } from 'swiper'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { GiFlagObjective, GiStairsGoal } from 'react-icons/gi'
// import { FcIdea } from 'react-icons/fc'
import { GiStairsGoal } from 'react-icons/gi'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const Index = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div
      name='programmes'
      className='h-auto w-full flex flex-col items-center justify-center mt-10 sm:mt-8'
    >
      <div className='aboutimage bg-fixed w-full flex flex-col justify-center items-center p-3 sm:p-8'>
        <div className=''>
          <h1
            data-aos='fade-up'
            data-aos-duration='1500'
            className='text-[25px] sm:text-[36px] uppercase font-extrabold'
          >
            <span className='text-[#caa046]'>Programme </span>
            <span className='text-[#caa046]'>We Offer</span>
          </h1>
          <hr />
        </div>
        <div className='flex flex-col justify-center items-center w-full mt-10 sm:mt-28'>
          <div className='heroimage block lg:flex justify-center items-center shadow space-x-0 lg:space-x-5 ml-5 mr-5'>
            <div className='p-2 lg:p-10 w-auto lg:w-[60vh]'>
              <h1 className='tracking-wide flex items-center space-x-2 capitalize text-[16px] sm:text-[25px] font-bold text-[#66071e]'>
                B.SC. CSIT
              </h1>
              <div className='space-y-4 mt-4'>
                <div>
                  <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                    B.Sc.CSIT offered by Institute of Science and Technology,
                    Tribhuvan University, Nepal is a four years – 8 semesters –
                    academic program.
                  </p>
                </div>
                <div>
                  <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                    This program ensures that graduates have a broad basic
                    knowledge and creative skills to conceive, design, build and
                    exploit Information Processing Systems. In addition to this,
                    the program also stimulates development of soft skills such
                    as team work, effective communication, technology management
                    etc. These skills are acquired through the execution of
                    various projects, lab works, presentations, case studies and
                    seminars throughout the entire program.
                  </p>
                </div>
                <div>
                  <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-relaxed sm:leading-loose'>
                    It is a challenging and demanding program. Nevertheless it
                    is also rewarding and fun. Thus it is the right program for
                    students who are passionate about Technology and
                    Communication.
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos='fade-left'
              data-aos-duration='1500'
              className='block lg:flex items-center justify-center p-2'
            >
              <img
                className='p-0 lg:p-10 rounded-[6px] h-auto md:h-[500px] w-full md:w-[500px]'
                src={Building}
                alt='soch college'
              />
            </div>
          </div>
        </div>

        <div className='aboutimage bg-fixed w-full flex flex-col justify-center items-center p-3 sm:p-10'>
          <h1
            data-aos='fade-up'
            data-aos-duration='1500'
            className='text-[25px] sm:text-[36px] uppercase font-extrabold'
          >
            <span className='text-[#caa046]'>Course </span>
            <span className='text-[#caa046]'>Objective</span>
          </h1>
        </div>
        <div className='flex flex-col items-center justify-center w-[69%] mt-10 sm:mt-26 space-y-26 p-15'>
          <div className='block lg:flex justify-center items-center space-y-6 lg:space-y-0 space-x-0  xl:space-x-12'>
            <div
              data-aos='fade-right'
              data-aos-duration='1500'
              className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
            >
              <div className='card-body'>
                <div className='flex items-center'>
                  <span>
                    <GiStairsGoal className='text-4xl mr-4 text-[#F2c460]' />
                  </span>
                </div>
                <hr />
                <p
                  className='items-center text-center sm:text-justify break-normal 
                  leading-tight sm:leading-normal tracking-tight sm:tracking-wide mt-2'
                >
                  To lay ground work for successful career in reputed IT
                  companies and other related institutions.
                </p>
              </div>
            </div>

            <div
              data-aos='fade-up'
              data-aos-duration='1500'
              className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
            >
              <div className='card-body'>
                <div className='flex items-center'>
                  <span>
                    <GiStairsGoal className='text-4xl mr-4 text-[#F2c460]' />
                  </span>
                </div>
                <hr />
                <p
                  className='items-center text-center sm:text-justify leading-tight 
                  sm:leading-normal tracking-tight sm:tracking-wide mt-2'
                >
                  To provide in depth knowledge in the field of theory, design
                  programming and application of computers
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col items-center justify-center mt-10 sm:mt-26 space-y-26 p-15'>
            <div className='block lg:flex justify-center items-center space-y-6 lg:space-y-0 space-x-0  xl:space-x-12'>
              <div
                data-aos='fade-right'
                data-aos-duration='1500'
                className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
              >
                <div className='card-body'>
                  <div className='flex items-center'>
                    <span>
                      <GiStairsGoal className='text-4xl mr-4 text-[#F2c460]' />
                    </span>
                  </div>
                  <hr />
                  <p
                    className='items-center text-center sm:text-justify leading-tight 
                  sm:leading-normal tracking-tight sm:tracking-wide mt-2'
                  >
                    To help students develop analytical skills that is essential
                    for problem solving.
                  </p>
                </div>
              </div>

              <div
                data-aos='fade-up'
                data-aos-duration='1500'
                className='card w-[99%] sm:w-96 bg-[whitesmoke] shadow border'
              >
                <div className='card-body'>
                  <div className='flex items-center'>
                    <span>
                      <GiStairsGoal className='text-4xl mr-4 text-[#F2c460]' />
                    </span>
                  </div>
                  <hr />
                  <p
                    className='items-center text-center sm:text-justify leading-tight 
                  sm:leading-normal tracking-tight sm:tracking-wide mt-2'
                  >
                    To provide skillful IT professionals who can contribute to
                    development of ICT sector of the Nation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='heroimage p-2 lg:p-16 mt-8 mb-16 ml-5 mr-5 lg:m-38 w-[80%]'>
        {/* <h1 className='tracking-wide flex items-center space-x-2 capitalize text-[16px] sm:text-[25px] font-bold'>
          <span className='text-[#caa046]'>Course </span>
          <span className='text-[#caa046]'>Structure</span>
        </h1> */}

        <div className='w-full flex flex-col justify-center items-center p-3 sm:p-10'>
          <h1
            data-aos='fade-up'
            data-aos-duration='1500'
            className='text-[25px] sm:text-[36px] uppercase font-extrabold'
          >
            <span className='text-[#caa046]'>Course </span>
            <span className='text-[#caa046]'>Structure</span>
          </h1>
        </div>

        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold '>First Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div
              className='block md:flex justify-between space-y-4 space-x-0 
            md:space-x-16 items-center collapse-content bg-primary text-primary-content
             peer-checked:bg-primary peer-checked:text-secondary-content'
            >
              <div className=''>
                <p
                  className='text-white items-center text-left sm:text-justify
                 tracking-tight sm:tracking-wide leading-loose pt-4'
                >
                  <li>
                    Introduction to Information Technology{' '}
                    <strong className='hidden'>
                      Introduction to Information Technology
                    </strong>{' '}
                  </li>
                  <li> C Programming </li>
                  <li> Digital Logic</li>
                  <li> Mathematics I </li>
                  <li> Physics </li>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Second Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li>Discrete Structure</li>
                  <li>Object-Oriented Programming</li>
                  <li>Microprocessor </li>
                  <li>Mathematics II </li>
                  <li>Statistics I</li>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div
              className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] 
             sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white
              peer-checked:bg-white peer-checked:text-primary duration-300'
            >
              <span className='font-bold'>Third Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div
              className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 
            items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'
            >
              <div>
                <p
                  className='text-white items-center text-left sm:text-justify
                 tracking-tight sm:tracking-wide leading-loose pt-4'
                >
                  <li>Data Structure and Algorithm</li>
                  <li>Numerical Method </li>
                  <li>Computer Architecture </li>
                  <li>Computer Graphics</li>
                  <li>Statistics II</li>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Fourth Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li>Theory of Computation </li>
                  <li>Computer Networks </li>
                  <li>Operating Systems</li>
                  <li> Database Management System </li>
                  <li>Artificial Intelligence </li>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Fifth Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li>Design and Analysis of Algorithms</li>
                  <li>System Analysis and Design </li>
                  <li>Cryptography </li>
                  <li>Simulation and Modeling </li>
                  <li>Web Technology </li>
                  <li> Elective I </li>
                  Electives
                  <li>Multimedia Computing </li>
                  <li>Wireless Networking </li>
                  <li>Image Processing </li>
                  <li>Knowledge Management </li>
                  <li>Society and Ethics in Information Technology</li>
                  <li> Microprocessor Based Design </li>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Sixth Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li>Software Engineering </li>
                  <li>Compiler Design and Construction </li>
                  <li>E-Governance </li>
                  <li>NET Centric Computing </li>
                  <li> Technical Writing </li>
                  <li> Elective II </li>
                  Electives
                  <li> Applied Logic </li>
                  <li> E-Commerce </li>
                  <li> Automation and Robotics </li>
                  <li> Neural Networks </li>
                  <li>Computer Hardware Design </li>
                  <li>Cognitive Science </li>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Seventh Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li> Advanced Java Programming </li>
                  <li>Data Warehousing and Data Mining </li>
                  <li> Principles of Management </li>
                  <li> Project Work </li>
                  <li> Elective III </li>
                  Electives
                  <li>Information Retrieval </li>
                  <li> Database Administrator </li>
                  <li> Software Project Management </li>
                  <li> Network Security </li>
                  <li> Digital System Design </li>
                  <li> International Marketing </li>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Eighth Semester</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose pt-4'>
                  <li> Advanced Database </li>
                  <li> Internship </li>
                  <li>Elective IV </li>
                  <li> Elective V </li>
                  Electives
                  <li> Advanced Networking with IPV6 </li>
                  <li>Distributed Networking </li>
                  <li> Game Technology </li>
                  <li> Distributed and Object-Oriented Database </li>
                  <li> Introduction to Cloud Computing </li>
                  <li> Geographical Information System </li>
                  <li> Decision Support System and Expert System </li>
                  <li> Mobile Application Development </li>
                  <li> Real-Time Systems </li>
                  <li> Network and System Administration </li>
                  <li>Embedded Systems Programming </li>
                  <li> International Business Management </li>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='heroimage p-2 lg:p-16 mt-28 mb-16 ml-5 mr-5 lg:m-38 w-[80%]'>
        <div className='w-full flex flex-col justify-center items-center p-3 sm:p-10'>
          <h1
            data-aos='fade-up'
            data-aos-duration='1500'
            className='text-[25px] sm:text-[36px] uppercase font-extrabold'
          >
            <span className='text-[#caa046]'>College </span>
            <span className='text-[#caa046]'>Infrastructure</span>
          </h1>
        </div>
        <p className='w-[97%] sm:w-auto items-center text-justify tracking-tight sm:tracking-wide leading-loose mt-2'>
          The college has its own property spread over 7 ropanies of land. A
          newly constructed building (Area 15000 Square Feet) with modern
          amenities with an added attraction of peaceful environment and
          greenery.
        </p>
        <div className='flex flex-col justify-center items-center mt-10 space-y-2'>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white  peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Computer Lab</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between space-y-4 space-x-0 md:space-x-16 items-center collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  The computer lab at SOCH has 48 PCs, one internet/file server
                  and other switching/networking devices. Each PC is configured
                  with software and tools required for various practical
                  sessions. For a smooth and fast internet connectivity, we have
                  a dedicated optical fiber link.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={ComputerLab}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center  justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Digital Lab</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white break-all sm:break-normal items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  The digital lab at SOCH has sufficient sets of Digital logic
                  trainer kit and DYNA-85 Microprocessor kit each.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded w-[500px] h-[300px]'
                  src={DigitalLab}
                  alt='computer lab'
                />
              </div>
            </div>
          </div>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex justify-between items-center tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Library</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white break-all sm:break-normal items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  The college has an extensive library containing more than 1000
                  volumes of various subject books, encyclopedias, biographies,
                  dictionaries and old projects. Furthermore our library
                  contains subscribed newspapers, magazines - both national and
                  international - and journals.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={Library}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Sports</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  Students at SOCH have access to outdoor sports like table
                  tennis, cricket and five-a-side football pitch located inside
                  the college premises. Moreover students can play indoor games
                  like chess in their leisure.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={Sports}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>
          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>AI Lab</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  In partnership with 'All India Council for Robotics and
                  Automation', we have a full-fledged AI Labs with 3D Printers,
                  Ardunino Boards, Sensors, Drones, various Digital, and
                  Electrical equipment, high-performance Server, and i7, i9
                  Computers, which serves as a hub for innovative thinking,
                  foster creativity, conducting experiments and prototyping AI
                  models.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={AILab}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>

          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>CISCO Network Lab</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  Networking Lab at Infomax has sufficient CISCO switches,
                  routers, and high-performance desktops that help students to
                  understand network protocols, practice network troubleshooting
                  skills, or design and implement a secure network
                  infrastructure.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={NetworkLab}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>

          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Lecture Hall</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-y-4 space-x-0 md:space-x-16 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  Lecture hall in our college is organized with modern furniture
                  and visual aids with tall windows for adequate lighting and
                  ventilation to ensure a comfortable and conducive learning
                  atmosphere.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={LecturerHall}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>

          <div className='group collapse rounded w-full'>
            <input type='checkbox' className='peer' />
            <div className='flex items-center justify-between  tracking-normal sm:tracking-wide capitalize text-[16px] sm:text-[25px] font-[400] collapse-title bg-[#66071e] text-white peer-checked:bg-white peer-checked:text-primary duration-300'>
              <span className='font-bold'>Canteen</span>
              <span className=''>
                <IoIosArrowDropdown />
              </span>
            </div>
            <div className='block md:flex justify-between items-center space-x-0 md:space-x-16 space-y-4 collapse-content bg-primary text-primary-content peer-checked:bg-primary peer-checked:text-secondary-content'>
              <div>
                <p className='text-white items-center text-left sm:text-justify tracking-tight sm:tracking-wide leading-loose'>
                  The canteen situated at the top of the college building with
                  panoramic view of Annapurna range provides freshly prepared
                  snacks and meals in affordable price range for students.
                </p>
              </div>
              <div className='mt-2'>
                <img
                  className='rounded'
                  src={Canteen}
                  width='800px'
                  height='800px'
                  alt='computer lab'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
;<style jsx>
  {`
    .checkbox {
      width: 10vw; /* Adjust the value as needed */
      height: 2vh; /* Adjust the value as needed */
    }
  `}
</style>

export default Index
