import React, { useEffect } from 'react'
import { GoLocation } from 'react-icons/go'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'
import { BsTelephoneOutbound, BsMessenger } from 'react-icons/bs'
import { FaViber, FaWhatsappSquare } from 'react-icons/fa'
import { AiFillFacebook } from 'react-icons/ai'
import Aos from 'aos'
import 'aos/dist/aos.css'

function Index() {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div
      name='contact'
      className='h-auto w-full flex flex-col justify-center items-center mt-10 sm:mt-32'
    >
      <div className='mb-16'>
        <h1
          data-aos='fade-up'
          data-aos-duration='1500'
          className='text-[25px] sm:text-[36px] uppercase font-extrabold'
        >
          <span className='text-[#caa046]'>Contact </span>
          <span className='text-[#caa046]'>Us</span>
        </h1>
        <hr />
      </div>
      <div className='flex justify-center items-center w-[95%]'>
        <div className='block lg:flex justify-center items-center bg-[whitesmoke] shadow space-x-0 lg:space-x-12'>
          <div
            data-aos='fade-right'
            data-aos-duration='1500'
            className='aboutimage p-10 rounded-[6px] -ml-[0px] shadow-none sm:shadow lg:-ml-[100px] h-auto md:h-[50vh] lg:h-[45vh] xl:h-[40vh] text-[#66071e]'
          >
            <h1 className='text-[18px] sm:text-[25px]'>Contact Us</h1>
            <hr />
            <div className='mt-4 space-y-10 mb-4 text-slate-600'>
              <p className='flex items-center'>
                <span className='mr-6'>
                  <GoLocation className='text-[brown] hover:text-[#66071e] cursor-pointer' />
                </span>
                Ranipauwa - Pokhara, Nepal
              </p>
              <p className='flex items-center'>
                <a
                  className='mr-6'
                  href='tel:+977 61574096'
                  target='_blank'
                  rel='noreferrer'
                >
                  <BsTelephoneOutbound className='text-[brown] hover:text-[#66071e] cursor-pointer' />
                </a>
                +977-61574096/543120
              </p>
              <p className='flex items-center'>
                <a
                  rel='noreferrer'
                  className='mr-6'
                  href='mailto:info@sochcollege.edu.np'
                  target='_blank'
                >
                  <MdOutlineMarkEmailUnread className='text-[brown] hover:text-[#66071e] cursor-pointer' />
                </a>
                info@sochcollege.edu.np
              </p>
            </div>
            <hr />
            <div className='flex items-center mt-4 space-x-6'>
              <span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.facebook.com/sochcollegeofit'
                >
                  <AiFillFacebook className='text-4xl text-[#4267B2] hover:text-[#5674af] duration-300 cursor-pointer' />
                </a>
              </span>
              <span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='viber://contact?number=%2B9779856037892'
                >
                  <FaViber className='text-4xl text-[#7360f2] hover:text-[#8c7cf1] duration-300 cursor-pointer' />
                </a>
              </span>
              <span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://api.whatsapp.com/send?phone=9779856037892'
                >
                  <FaWhatsappSquare className='text-4xl text-[#25d366] hover:text-[#5ece81] duration-300 cursor-pointer' />
                </a>
              </span>
              <span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://m.me/sochcollegeofit'
                >
                  <BsMessenger className='text-4xl text-[#006AFF] hover:text-[#00B2FF] duration-300 cursor-pointer' />
                </a>
              </span>
            </div>
          </div>
          <div className='p-10'>
            <h1 className='text-[18px] sm:text-[25px]'>Get In Touch</h1>
            <hr />
            <form
              action='https://formsubmit.co/efdc8f1f0258fc6b4e0deb8530ec3576'
              method='POST'
              className='space-y-4 mt-4'
            >
              <div>
                <p>
                  Name <span className='text-[crimson]'>*</span>
                </p>
                <input
                  className='w-full sm:w-[50vh] h-[5vh] rounded-[6px] p-2 outline-[#66071e]'
                  placeholder='Please enter your name'
                  required
                  type='text'
                  name='name'
                />
              </div>
              <div>
                <p>
                  Phone Number <span className='text-[crimson]'>*</span>
                </p>
                <input
                  className='w-full sm:w-[50vh] h-[5vh] rounded-[6px] p-2 outline-[#66071e]'
                  placeholder='+977-980000000000'
                  required
                  type='tel'
                  name='tel'
                />
              </div>
              <div>
                <p>
                  Email Address <span className='text-[crimson]'>*</span>
                </p>
                <input
                  className='w-full sm:w-[50vh] h-[5vh] rounded-[6px] p-2 outline-[#66071e]'
                  placeholder='example@gmail.com'
                  required
                  type='email'
                  name='email'
                />
              </div>
              <div>
                <p>
                  Write a message <span className='text-[crimson]'>*</span>
                </p>
                <textarea
                  className='w-full sm:w-[50vh] h-[15vh] rounded-[6px] p-2 outline-[#66071e]'
                  placeholder='Please type something...'
                  required
                  type='text'
                  name='message'
                />
              </div>
              <div>
                <button
                  type='submit'
                  className='text-[#66071e] bg-transparent border border-solid border-[#66071e] hover:duration-500 hover:bg-[#66071e] hover:text-white active:bg-[#5ece81]] font-bold uppercase text-sm px-4 py-3 rounded outline-none focus:outline-none  ease-linear transition-all duration-150  active show'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        data-aos='fade-up'
        data-aos-duration='1500'
        className='w-[90%] lg:w-[45%] h-[30vh] grayscale rounded-lg mt-10'
      >
        <iframe
          className='rounded-lg shadow'
          width='100%'
          height='200px'
          frameBorder='0'
          marginHeight='0'
          marginWidth='0'
          title='Map'
          src='https://www.google.com/maps/embed/v1/place?q=Soch+College+of+IT,+Pokhara,+Nepal&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8'
        ></iframe>
      </div>
    </div>
  )
}

export default Index
