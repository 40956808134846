import { useState } from 'react'
import About from './components/About/index'
import { Lightbox } from 'react-modal-image'
import soch from '../src/assets/soch.jpeg'
import Navbar from './components/Navbar/index'
import Footer from './components/Footer/index'
import Landing from './components/Landing/index'
import Gallery from './components/Gallery/index'
import Contact from './components/Contact/index'
import Programmes from './components/Programmes/index'

function App() {
  const [modelopen, setModelOpen] = useState(true)

  const closeLightbox = () => {
    setModelOpen(false)
  }

  return (
    <>
      {modelopen && (
        <Lightbox
          medium={soch}
          hideZoom={true}
          hideDownload={true}
          onClose={closeLightbox}
          className='my-4'
        />
      )}
      <div className='overflow-hidden scroll-smooth'>
        <Navbar />
        <Landing />
        <About />
        <Programmes />
        <Gallery />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default App
