import React from 'react'
import Logo from '../../assets/sochlogo.png'
import { Link } from 'react-scroll'

function Index() {
  return (
    <div className='bg-[whitesmoke] w-full h-auto flex flex-col justify-center items-center p-5 sm:p-10 shadow-sm mt-10 sm:mt-0'>
      <div className='block lg:flex justify-between items-center w-full mb-16 space-y-14 lg:space-y-0'>
        <div className='block lg:flex justify-center items-center space-x-0 md:space-x-4'>
          <div className='flex items-center justify-center mb-4'>
            <img src={Logo} width='110px' height='110px' alt='soch college' />
          </div>
          <hr />
          <p className='mt-4 md:mt-0 w-auto lg:w-[50vh] items-center text-justify leading-relaxed sm:leading-loose'>
            Affiliated to Tribhuvan University, SOCH College situated in the
            beautiful city of Pokhara. Since its inception, SOCH has been
            delivering quality education in Information Technology.
          </p>
        </div>
        <div className='text-left'>
          <h1 className='uppercase text-center sm:text-left font-bold text-sm sm:text-lg text-[#caa046] mb-2'>
            Subscribe our newsletter
          </h1>
          <hr />
          <form
            action='https://formsubmit.co/efdc8f1f0258fc6b4e0deb8530ec3576'
            method='post'
            className='mt-4 flex flex-col space-y-2'
          >
            <input
              className='w-auto lg:w-[50vh] h-[5vh] rounded-[6px] p-2 border border-slate-300 outline-[#66071e]'
              type='text'
              placeholder='Enter your valid email address *'
              required
            />
            <button
              type='submit'
              className='text-[#66071e] w-auto lg:w-[50vh] bg-transparent border border-solid border-[#66071e] hover:duration-500 hover:bg-[#66071e] hover:text-white active:bg-[#66071e] font-bold uppercase text-sm px-4 py-3 rounded outline-none focus:outline-none  ease-linear transition-all duration-150  active show'
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className='w-[95%]'>
        <hr />
        <div className='space-y-4'>
          <div className='hidden md:flex items-center justify-center font-[500] text-lg'>
            <ul className='flex items-center justify-center space-x-24'>
              <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
                <Link to='home' smooth={true} duration={1400}>
                  Home
                </Link>
              </li>
              <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
                <Link to='about' smooth={true} duration={1100}>
                  About
                </Link>
              </li>
              <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
                <Link to='programmes' smooth={true} duration={800}>
                  Programmes
                </Link>
              </li>
              <li className='text-slate-800 hover:text-[#66071e] hover:underline hover:underline-offset-8 cursor-pointer hover:duration-300'>
                <Link to='gallery' smooth={true} duration={500}>
                  Gallery
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <center className='mt-10 text-sm'>
          Copyright © 2022, ALL RIGHTS RESERVED.
        </center>
      </div>
    </div>
  )
}

export default Index
