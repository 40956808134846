import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-scroll'

function Index() {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div
      name='home'
      className='homesection bg-cover bg-top sm:bg-center bg-no-repeat bg-fixed h-screen w-full flex items-center justify-end'
    >
      <div className=' mr-5 sm:mr-10'>
        <p
          data-aos='fade-down'
          data-aos-duration='1500'
          className='flex text-[#66071e] items-end uppercase text-[25px] sm:text-[37px] md:text-[52px] lg:text-[72px] font-extrabold'
        >
          Soch College Of
          <span className='text-[#caa046] ml-2 lg:ml-6'> IT</span>
        </p>
        <hr />
        <div
          data-aos='fade-up'
          data-aos-duration='2500'
          className='flex items-end justify-end mt-3 sm:mt-6'
        >
          <Link
            className='text-[#66071e] bg-transparent border border-solid
          border-[#66071e] hover:duration-500 hover:bg-[#66071e]
          hover:text-white active:bg-[#66071e] font-bold uppercase text-sm
          px-2 py-1 sm:px-5 sm:py-2 md:px-10 md:py-3 rounded outline-none focus:outline-none ease-linear
          transition-all duration-150 active show cursor-pointer'
            to='about'
            smooth={true}
            duration={700}
          >
            Explore
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Index
